<template>
  <div class="MoreData">
    <ul class="issue-list">
      <li>问卷：{{ infoData.qaTitle }}</li>
      <li>问题：{{ infoData.OptionTitle }}</li>
    </ul>
    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="false"
      bordered
    ></a-table>
  </div>
</template>

<script>
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'MoreData',
  data () {
    return {
      columns: [
        {
          title: '回答内容',
          dataIndex: 'Answer',
          align: 'center'
        },
        {
          title: '调查对象',
          dataIndex: 'UserType',
          align: 'center',
          width: 150
        },
        {
          title: '添加人',
          dataIndex: 'UserName',
          align: 'center',
          width: 150
        }
      ],
      tableData: [
        {
          key: 0,
          content:
            '使肌肤和的数据库京东数科建安费很多框架是大恒科技发多少反倒是看见暗黑风金卡戴珊返回的刷卡机',
          surveyObject: '陈星',
          add: '李泳章'
        }
      ],
      infoData: ''
    }
  },
  created () {
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.$message.loading('加载中...', 0)
    this.$axios.get(
      5836114,
      { Qid: this.$route.query.Qid, OptionId: this.$route.query.id },
      res => {
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.msg, 0)
        }
        this.$message.destroy()
      }
    )
  }
}
</script>

<style scoped lang="less">
.MoreData {
  height: 100%;
  padding: 20px;
  background: #fff;
  .issue-list {
    margin-bottom: 30px;
    > li {
      padding: 7px 10px;
      border: 1px solid #ddd;
      border-bottom: none;
    }
    li:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
}
</style>
