import { render, staticRenderFns } from "./MoreData.vue?vue&type=template&id=31f05f24&scoped=true&"
import script from "./MoreData.vue?vue&type=script&lang=js&"
export * from "./MoreData.vue?vue&type=script&lang=js&"
import style0 from "./MoreData.vue?vue&type=style&index=0&id=31f05f24&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f05f24",
  null
  
)

export default component.exports